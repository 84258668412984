import React from 'react'

function commingsoon() {
  return (
    <div className='comming'>
          <div className="conto">
        <div className="coming-soon">
            <h1 className='cs'>COMING SOON</h1>
            <p className='cp'>UNDER CONSTRUCTION</p>
            <form className="notify-form">
                <input type="email" placeholder="Enter Your Email"/>
                <button type="submit">Notify Me</button>
            </form>
        </div>
    </div>
    </div>
  )
}

export default commingsoon