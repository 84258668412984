import React, { Component } from "react";

const title = "You’re Just 3 Steps Away From A Great Date";
const subtitle = "How Does It Work?";

let WorkListContent = [
  {
    imgUrl: "assets/images/myimg/Artboard.png",
    imgAlt: "work thumb",
    step: "step",
    stepCount: "01",
    title: "Create A Profile",
    desc: "Continua actualize ailers through robu and sertively concepze standards compliant commerce after technica sound.",
  },
  {
    imgUrl: "assets/images/work/02.png",
    imgAlt: "work thumb",
    step: "step",
    stepCount: "02",
    title: "Find Matches",
    desc: "Continua actualize ailers through robu and sertively concepze standards compliant commerce after technica sound.",
  },
  {
    imgUrl: "assets/images/work/03.png",
    imgAlt: "work thumb",
    step: "step",
    stepCount: "03",
    title: "Start Dating",
    desc: "Continua actualize ailers through robu and sertively concepze standards compliant commerce after technica sound.",
  },
];

class WorkSection extends Component {
  state = {};
  render() {
    return (

      <section class="work-section padding-tb">
        <div class="container">
          <div class="section-header">
            <h4 class="theme-color">How Does It Work?</h4>
            <h2>You’re Just 3 Steps Away From A Great Date</h2>
          </div>
          <div class="section-wrapper">
            <div class="row justify-content-center g-5">
              <div class="col-lg-4 col-sm-6 col-12 px-4">
                <div class="lab-item work-item">
                  <div class="lab-inner text-center">
                    <div class="lab-thumb">
                      <div class="thumb-inner">

                        <div className="ufffimg1"></div>

                        <div class="step">
                          <span>step</span>
                          <p>01</p>
                        </div>
                      </div>
                    </div>
                    <div class="lab-content">
                      <h4>Register Your Interest</h4>
                      <p>
                      Complete our simple registration form to express your interest.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 px-4">
                <div class="lab-item work-item">
                  <div class="lab-inner text-center">
                    <div class="lab-thumb">
                      <div class="thumb-inner">

                        <div className="ufffimg2"></div>
                        <div class="step">

                          <span>step</span>
                          <p>02</p>
                        </div>
                      </div>
                    </div>
                    <div class="lab-content">
                      <h4>Attend Exclusive Events</h4>
                      <p>
                      Join us at our bespoke events in luxurious locations around the world.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-sm-6 col-12 px-4">
                <div class="lab-item work-item">
                  <div class="lab-inner text-center">
                    <div class="lab-thumb">
                      <div class="thumb-inner">
                      <div className="ufffimg3"></div>
                        <div class="step">
                          <span>step</span>
                          <p>03</p>
                        </div>
                      </div>
                    </div>
                    <div class="lab-content">
                      <h4>Meet Your Match</h4>
                      <p>
                      Engage with verified members and find your perfect match.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WorkSection;
