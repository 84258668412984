import React from 'react'
import { NavLink, Link } from "react-router-dom";

function header2() {
  return (
   
<div className="mainnn">
    
    <div>
         <NavLink to="/" ><div className='logoo'></div> </NavLink>
    </div>

<div className='mainlog'>
    <NavLink to="/" className="login"><div className="log"></div> </NavLink>
    <NavLink to="/" className="signup"><div className="sig"></div> </NavLink>
 </div>    

    </div>

  )
}

export default header2