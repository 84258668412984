import React, { Component } from 'react';
import SectionHeader from './sectionheader';
import { Link } from 'react-router-dom';
function events() {
  return (



<div classNameName="event">
  <div className="main-container">
    <div className="header">
      <h1 className='abo'>Upcoming Exclusive Events</h1>
      <p>Join us for exclusive events around the world, offering unparalleled luxury and opportunities to connect.</p>
    </div>
    <div className="cards-container">
      <div className="card">
        <div className="card-content">
          <h5>Upcoming Events In</h5>
          <h2>Dubai</h2>
          <p>Join us for an exclusive event in Dubai, offering unparalleled luxury and opportunities to connect.</p>
        </div>
        <div className="card-footer">
        <Link to="" className="lab-btn "><span>PURCHASE</span></Link>
        </div>
      </div>
      <div className="card">
        <div className="card-content">
          <h5>Upcoming Events In</h5>
          <h2>New York</h2>
          <p>Experience the vibrancy of New York with our high-profile matchmaking event.</p>
        </div>
        <div className="card-footer">
        <Link to="" className="lab-btn "><span>PURCHASE</span></Link>
        </div>
      </div>
      <div className="card">
        <div className="card-content">
          <h5>Upcoming Events In</h5>
          <h2>Riyadh</h2>
          <p>Engage with like-minded individuals in the heart of Riyadh, Saudi Arabia.</p>
        </div>
        <div className="card-footer">
        <Link to="" className="lab-btn "><span>PURCHASE</span></Link>
        </div>
      </div>
    </div>
  </div>

    </div>
  )
}

export default events
