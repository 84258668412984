import React, { Component } from 'react';
import Header from '../component/layout/header';
import Header2 from '../component/layout/header2';
import FooterSection from '../component/layout/footer';
import PageHeader from '../component/layout/pageheader';
import About from '../component/section/about'
import Clints from '../component/section/clints'



class services extends Component {
    render() {
        return (
            <>
            <div>
                <Header2/>
                <Header />
                <PageHeader title={'Services'} />
                <About/>    
                <Clints/>
                <FooterSection />
            </div>
            
            </>
        );
    }
}

export default services;