import React, { Component } from "react";

class AppsSection extends Component {
  state = {};
  render() {
    return (
      <section class="app-section bg-theme">
        <div class="container">
          <div class="section-wrapper padding-tb">
            <div class="app-content">
              <h4>Download App Our "Secret Proposal"</h4>
              <h2>Easy Connect to Everyone </h2>
              <p>
                You find us, finally, and you are already in love. More than
                5.000.000 around the world already shared the same experience
                andng ares uses our system Joining us today just got easier!
              </p>
              <ul class="app-download d-flex flex-wrap">
                <li>
                  <a href="#" class="d-flex flex-wrap align-items-center">
                   
<div className="appstore"></div>


                  </a>
                </li>
                <li>
                  <a href="#" class="d-flex flex-wrap align-items-center">

                  <div className="googleplay"></div>

                  </a>
                </li>
              </ul>
            </div>
            <div class="mobile-app">
              <div className="mobilest"></div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AppsSection;
