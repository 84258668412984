import React, { Component } from 'react';
import SectionHeader from './sectionheader';
import { Link } from 'react-router-dom';

// const subtitle= <h4>About Our Turulav</h4>;
// const title= <h2>It All Starts With A Date</h2>;
// const alignment= 'section-header';

// let AboutContentList = [
//     {
//         imgUrl: '/assets/images/about/01.png',
//         imgAlt: 'About Thumb',
//         countNumber: '29,991',
//         countTitle: "Members in Total",
//     },
//     {
//         imgUrl: 'assets/images/about/02.png',
//         imgAlt: 'About Thumb',
//         countNumber: '29,000',
//         countTitle: "Members Online",
//     },
//     {
//         imgUrl: 'assets/images/about/03.png',
//         imgAlt: 'About Thumb',
//         countNumber: '14,000',
//         countTitle: "Men Online",
//     },
//     {
//         imgUrl: 'assets/images/about/04.png',
//         imgAlt: 'About Thumb',
//         countNumber: '15,000',
//         countTitle: "Women Online",
//     },
// ]


class AboutSection extends Component {
    // state = {  } 
    render() { 
        return (
            // <section className="about-section padding-tb bg-img" id='bak'>
            //     <div className="container" id='baz'>
            //         <SectionHeader 
            //             title={ title } 
            //             subtitle={ subtitle }
            //             alignment={alignment}
            //         />
            //         <div className="section-wrapper">
            //             <div className="row justify-content-center g-4">
            //                 {AboutContentList.map((val, i) =>(
            //                     <div className="col-xl-3 col-lg-4 col-sm-6 col-12" key={i}>
            //                         <div className="lab-item about-item">
            //                             <div className="lab-inner text-center">
            //                                 <div className="lab-thumb">
            //                                     <img 
            //                                         src={`${val.imgUrl}`} 
            //                                         alt={`${val.imgAlt}`} 
            //                                     />
            //                                 </div>
            //                                 <div className="lab-content">
            //                                     <h2 className="counter">{val.countNumber}</h2>
            //                                     <p>{val.countTitle}</p>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     </div>
            //                 ))}
            //             </div>
            //         </div>
            //     </div>
            // </section>
            <div className="">
            <div className="main-container">
              <div className="header">
                <h1 className='abo'>About Us</h1>
                <p>Welcome to Secret Proposal Capital Club, where we bring together high-net-worth 
                individuals through exclusive matchmaking events. Our mission is to create meaningful 
                connections in a secure and discreet environment.
                </p>
              </div>
              <div className="cards-container">
                <div className="card">
                  <div className="card-content">
                    <h5>Our Vision:</h5>
                    {/* <h2>Dubai</h2> */}
                    <p> Our vision is to redefine the art of matchmaking by providing a platform for elite 
                    individuals to find their perfect match through curated events and personalized services</p>
                  </div>
                  <div className="card-footer">
                  <Link to="/" className="lab-btn "><span>read more</span></Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-content">
                    <h5>Our Values:</h5>
                    {/* <h2>New York</h2> */}
                    {/* <p><div>• Privacy:</div> Your confidentiality is our top priority.<br/>
                       <div>• Exclusivity:</div> We host events for verified, high-net-worth individuals.<br/>
                      <div>• Quality:</div> We partner with reputable brands to ensure the highest standards.</p> */}
                      

                      <div className="ka">
                <div className="ka1"> Privacy:</div> <hr /> <div>Your confidentiality is our top priority.</div>
                </div>
                <div className="ka">
                <div className="ka1"> Exclusivity:</div><hr />  <div>We host events for verified, high-net-worth individuals.</div>
                </div>
                <div className="ka">
                <div className="ka1"> Quality:</div> <hr /> <div>We partner with reputable brands to ensure the highest standards.</div>
                </div>

                  </div>
                  <div className="card-footer">
                  <Link to="/" className="lab-btn "><span>read more</span></Link>
                  </div>
                </div>
                <div className="card">
                  <div className="card-content">
                    <h5>Our Team:</h5>
                    {/* <h2>Riyadh</h2> */}
                    <p>Meet the dedicated team behind Secret Proposal Capital Club, committed to making your 
                    matchmaking journey seamless and enjoyable.</p>
                  </div>
                  <div className="card-footer">
                  <Link to="/" className="lab-btn "><span>read more</span></Link>
                  </div>
                </div>
              </div>
            </div>
          
              </div>
       
       
       
        );

    }
}
 
export default AboutSection;