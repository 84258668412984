import React, { Component } from 'react';
import Header from '../component/layout/header';
import Header2 from '../component/layout/header2';
import Events from '../component/section/events';
import Work from '../component/section/work';
import FooterSection from '../component/layout/footer';
import PageHeader from '../component/layout/pageheader';
// import About from '../component/section/about'
// import Slider from '../component/section/slider';
import Clints from '../component/section/clints'



class HomePages extends Component {
    render() {
        return (
            <>
            <div>
                <Header2/>
                <Header />
                <PageHeader title={'Events'} />
                {/* <About/> */}
                {/* <Work/> */}
                <Events/>
                {/* <Slider/>  */}
                <Clints/>
                <FooterSection />
            </div>
            
            </>
        );
    }
}

export default HomePages;
